@import "./../../common/styles";

$grey: #f0f0f0;
$transition-all: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
$full-page-height: calc(100vh - 113px - 574px);
$shadow: #ccd7f1;

/* *********** visual style starts *********** */
:root {
  font-size: 14px;
  --primaryColor: #df6c00;
  --secondaryColor: #008bf0;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;

  &.color-link {
    color: var(--primaryColor);

    &:hover {
      text-decoration: underline;
    }
  }
}

.hide {
  display: none;
}

html {
  background: #fff;
  font-family: Tahoma, "Microsoft YaHei", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  margin: 0;
  overflow-x: hidden;
  width: 100%;
}

body {
  margin: 0;
  width: 100%;
}

body .body-wrapper {
  opacity: 0;
  transition: opacity 150ms ease-in;
  background-color: #fff;
  color: #707070;
}
body.ready .body-wrapper {
  opacity: 1;
}

button {
  cursor: pointer;
}

input:focus {
  outline: 0;
}

.container {
  max-width: 1200px;
  margin: auto;
  width: 100%;
}

.svg-mask {
  &.resize {
    .image {
      transform: scale(0.71);
    }
  }
  .image {
    background-color: var(--primaryColor);
    mask-repeat: no-repeat;
    mask-position: center;
    margin: auto;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  }
}

/* *********** header *********** */
header {
  .top-login {
    background-color: $grey;
    min-height: 35px;
    display: flex;
    align-items: center;
    padding: 5px 0;

    .login-wrapper {
      display: flex;
      align-items: center;

      .login-box {
        display: inline-flex;
        margin-left: auto;
        &.hide {
          display: none;
        }
        input {
          background-color: transparent;
          border: 1px solid #c0c0c0;
          border-radius: 4px;
          padding: 6px 10px 6px 25px;
          margin-left: 5px;
          &::placeholder {
            text-align: right;
          }
        }
        .username {
          background: url(../img/input_username.svg) 4px center no-repeat;
        }
        .password {
          background: url(../img/input_pwd.svg) 4px center no-repeat;
        }
        .vcode {
          position: relative;
          input {
            padding: 6px 10px;
            &::placeholder {
              text-align: left;
            }
          }
          .verify-code {
            position: absolute;
            right: 0;
            top: 1px;
            width: 54px;
            height: 90%;
          }
        }
        .forgot-pwd {
          border: 0;
          border-radius: 20px;
          padding: 2px 6px;
          color: #4f94e8;
          display: flex;
          align-items: center;
          white-space: nowrap;
        }
        .login-buttons {
          border: 0;
          border-radius: 4px;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1rem;
          margin-left: 5px;
          width: 78px;
          transition: $transition-all;
          &.red {
            background-color: #c1272d;
          }
          &.blue {
            background-color: #008bf0;
          }
          &.purple {
            background-color: #cc52ea;
          }
          &:hover {
            background-color: var(--primaryColor);
          }
        }
      }
      .header-links {
        margin-left: auto;
        display: flex;
        &.hide {
          display: none;
        }
        ul {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 3px 0;
          li {
            list-style: none;
            &:not(:last-child) {
              margin-right: 20px;
            }
            a {
              color: var(--primaryColor);
              white-space: nowrap;
              transition: $transition-all;
              &:hover {
                color: var(--secondaryColor);
              }
            }
            span {
              color: #000;
            }
          }
        }
      }
    }
  }

  .top-nav {
    background-color: #fff;
    color: #000;
    .menu {
      display: flex;
      align-items: center;
      position: relative;
      .logo {
        flex: 1;
        text-align: center;
        img {
          height: 54px;
          padding: 5px;
        }
      }
      .nav {
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-left: auto;
        .menu-button {
          height: 68px;
          width: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          position: relative;
          transition: $transition-all;
          @media screen and (max-width: 768px) {
            width: 75px;
          }
          &:before {
            background-color: var(--primaryColor);
            content: "";
            height: 3px;
            width: 100%;
            position: absolute;
            top: 0;
            transform: scaleX(0);
            transition: $transition-all;
          }
          &.active,
          &:hover {
            background-image: linear-gradient(#f0f0f0, #f7f7f7, #fff);
            &:before {
              transform: scaleX(1);
            }
          }
          img {
            margin-bottom: 6px;
          }
          &.lottery:hover + .lottery-dropdown {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }

  .lottery-dropdown {
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(10px);
    border-radius: 8px;
    position: absolute;
    left: 0;
    top: 68px;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    transition: $transition-all;
    z-index: 5;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      &.hide {
        display: none;
      }
      .lottery-box {
        flex-basis: calc(100% / 7);
        text-align: center;
        .image {
          height: 100px;
          width: 100px;
          &:hover {
            background-color: var(--secondaryColor);
          }
        }
      }
    }
    &:hover {
      opacity: 1;
      visibility: visible;
    }
  }
}

/* *********** footer *********** */
footer {
  background-color: #f2f2f2;
  .info {
    display: flex;
    padding-top: 40px;
    .service-col {
      color: var(--primaryColor);
      flex-basis: 50%;
      font-size: 1.2rem;
      .service-wrapper {
        width: 60%;
        .title {
          font-weight: bold;
        }
        .time-wrapper {
          display: flex;
          align-items: center;
          .time {
            display: flex;
            align-items: center;
            margin-left: auto;
            font-size: 1.6rem;
          }
        }
      }
      .cs-wrapper {
        display: flex;
        align-items: center;
        svg {
          margin-right: 10px;
        }
        .phone-num {
          font-size: 1.6rem;
          letter-spacing: 1px;
        }
      }
    }
    .brand-col {
      flex-basis: 50%;
      .logo {
        img {
          height: 68px;
        }
      }
      .title {
        font-size: 1.2rem;
        font-weight: bold;
      }
      .brand-ability {
        div {
          margin-bottom: 20px;
        }
      }
    }
  }

  .progress-bar {
    width: 100%;
    height: 7px;
    background: #d0bdbd;
    box-sizing: border-box;
    border-radius: 20px;
    position: relative;
    margin-top: 8px;
    margin-bottom: 26px;
    &.progress1 span,
    &.progress2 span {
      background-color: var(--primaryColor);
      position: absolute;
      left: 0;
      top: 0;
      height: 7px;
      border-radius: 20px;
      transform-origin: left;
      transform: scaleX(0);
    }
    &.progress1 span {
      width: 37%;
      animation: expand 3s ease-out forwards;
    }
    &.progress2 span {
      width: 60%;
      animation: expand 3s ease-out forwards;
    }
    @keyframes expand {
      to {
        transform: scaleX(1);
      }
    }
  }

  .payment {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    padding: 30px 0;
    .title {
      font-size: 1.2rem;
    }
    .col {
      flex-basis: 50%;
    }
    .payment-box-wrapper {
      display: flex;
      .payment-box {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-basis: calc(100% / 8);
        img {
          margin-bottom: 12px;
        }
        &.title {
          font-size: 1.2rem;
          justify-content: initial;
        }
      }
    }
    .techsup-wrapper {
      .sgwin-wrapper {
        display: flex;
        align-items: center;
        img {
          margin-right: 20px;
          margin-top: 5px;
        }
      }
    }
  }
  .disclaimer {
    background-color: var(--primaryColor);
    color: rgba(255, 255, 255, 0.5);
    .disclaimer-wrapper {
      display: flex;
      line-height: 18px;
      padding: 30px 0;
      .left {
        flex-basis: 20%;
        div:first-child {
          font-size: 1.6rem;
          font-weight: bold;
          margin-bottom: 6px;
        }
      }
      .right {
        flex-basis: 80%;
      }
    }
  }
}

/* ***** sidebar ****** */
.left-sidebar {
  color: #fff;
  height: 314px;
  width: 138px;
  text-align: center;
  position: fixed;
  z-index: 4;
  top: 25%;
  left: 22px;
  .left-qr-wrapper {
    background: url("../img/sidebar/navigator_bg.png") center no-repeat;
    height: 176px;
    margin-top: -15px;
    .appqr-wrapper {
      padding-top: 16px;
    }
    .vertical {
      background-color: #000;
      border-radius: 0 0 5px 5px;
      transform: rotate(90deg);
      transform-origin: top left;
      position: absolute;
      left: 16px;
      top: 143px;
      padding: 2px 10px;
    }
    .qr-code {
      background-color: #fff;
      height: 80px;
      width: 80px;
      margin: auto;
    }
  }
}

.right-sidebar {
  position: fixed;
  z-index: 4;
  top: 50%;
  transform: translateY(-50%);
  right: 22px;

  > a {
    position: relative;
    margin-bottom: 5px;
  }

  .right-item {
    background-color: var(--secondaryColor);
    border-radius: 8px;
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: $transition-all;
    .width-fit {
      width: 100%;
    }
    &:hover {
      background-color: var(--primaryColor);
    }
  }
}

.right-item {
  &:hover .sidebar-desc {
    transform: translate(0, -50%);
    opacity: 1;
    visibility: visible;
  }

  .sidebar-desc {
    min-width: 40px;
    max-width: 140px;
    display: block;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    text-decoration: none;
    position: absolute;
    top: 50%;
    transform: translate(-30%, -50%);
    right: 60px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 6px;
    padding: 5px;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 0.8125rem;
    color: #fff;
    //white-space: nowrap;
    transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      right: -10px;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 5px solid rgba(0, 0, 0, 0.7);
    }

    img,
    .qr-code {
      display: block;
      max-width: 130px;
    }

    div {
      word-break: break-all;
    }
  }
}

/* *********** index *********** */
.banner {
  height: 490px;
  position: relative;
  .swiper-container {
    height: 100%;
    .swiper-wrapper {
      height: 100%;
      .swiper-slide {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 0;
        overflow: hidden;
        position: relative;
        > img {
          height: 100%;
          z-index: 1;
          &.img-bg {
            position: absolute;
            width: 130%;
            height: auto;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            filter: blur(10px);
            z-index: -1;
          }
          &.img-words {
            position: absolute;
            height: 35%;
            left: 26%;
            transform: translateX(-1000px);
            transition: $transition-all;
            &.animate {
              transform: translateX(0);
            }
          }
        }
      }
    }
  }
}

.notice {
  background-color: #f2f2f2;
  color: #757575;
  height: 40px;
  line-height: 40px;
  .marquee-wrapper {
    display: flex;
    overflow: hidden;
    .notice-box {
      background-color: var(--primaryColor);
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 15px;
      white-space: nowrap;
      img {
        margin-left: 5px;
      }
    }
    .marquee-box {
      position: relative;
      box-sizing: border-box;
      line-height: 40px;
      height: 40px;
      padding: 0 8px;
      width: 92%;
      ul {
        overflow: hidden;
        zoom: 1;
        margin: 0;
        li {
          margin-right: 20px;
          float: left;
          height: 40px;
          line-height: 40px;
          text-align: left;
          display: inline;
          width: auto !important;
        }
      }
    }
  }
}

.hotgame {
  background: url("../img/hotgamebg.png") center no-repeat #fff;
  padding: 50px 0;
  .hotgame-wrapper {
    display: flex;
    .hotgame-left,
    .hotgame-right {
      flex-basis: 50%;
    }
    .hotgame-left {
      .hotgame-desc-box {
        margin: auto;
        line-height: 1.6rem;
        text-align: center;
        width: 40%;
        &:not(:last-child) {
          margin-bottom: 70px;
        }
        .title {
          color: var(--primaryColor);
          font-size: 2rem;
          font-weight: bold;
          line-height: 2.4rem;
          margin-bottom: 12px;
          margin-top: 4px;
        }
        .play-btn {
          background-color: var(--primaryColor);
          border: 0;
          border-radius: 4px;
          color: #fff;
          display: inline-block;
          margin-top: 20px;
          padding: 3px;
          width: 150px;
          transition: $transition-all;
          &:hover {
            background-color: var(--secondaryColor);
          }
          &.hide {
            display: none;
          }
        }
      }
    }
    .hotgame-right {
      color: var(--primaryColor);
      text-align: center;
      width: 60%;
      .title {
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 5px;
      }
      .game-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .game-box {
          flex-basis: calc(100% / 3);
          .image {
            height: 120px;
            width: 120px;
            &:hover {
              background-color: var(--secondaryColor);
            }
          }
        }
      }
      .game {
        margin-top: 20px;
        .swiper-pagination {
          position: unset;
        }
        .swiper-pagination-bullet-active {
          background: var(--primaryColor);
        }
      }
    }
  }
}

/* *********** faqs *********** */
.faq-outer {
  background: url(../img/faqbgline.png) left top repeat;
}
.faq {
  background: url(../img/faqbg_orange.png) center top no-repeat;
  padding: 30px;
  box-sizing: border-box;
  min-height: $full-page-height;
  p {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.faq-wrapper {
  background-color: $grey;
  display: flex;
  .faq-menu {
    border-right: 1px solid #d6d6d6;
    flex-basis: 17%;
    font-size: 1.2rem;
    .newbie {
      background-color: #b92328;
      color: #fff;
      padding: 20px;
      font-weight: bold;
      text-align: center;
    }
    span {
      cursor: pointer;
      display: block;
      padding: 20px 40px;
      box-sizing: border-box;
      transition: $transition-all;
      &.active,
      &:hover {
        background-color: #fff;
      }
    }
  }

  .faq-content-box {
    background: url("../img/faqgirl.png") top right no-repeat;
    background-position-y: 80px;
    flex-basis: 83%;
    padding: 20px 32px;
    a {
      color: var(--primaryColor);
    }
    h2 {
      border-bottom: 1px solid #656565;
      padding-bottom: 5px;
      color: var(--primaryColor);
    }
    h4 {
      margin-bottom: 5px;
    }
  }
}

/* *********** app *********** */
.app {
  background: url(../img/appbg.png) center no-repeat #dcdcdc;
  min-height: $full-page-height;
  padding-top: 36px;
}

.app-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  .phone-col {
    flex-basis: 40%;
    div:not(:last-child) {
      margin-bottom: 20px;
    }
    .img-phone {
      margin-bottom: 15px;
      animation: phone 8s ease-out infinite;
    }

    @keyframes phone {
      0% {
        transform: translateY(0);
      }
      20% {
        transform: translateY(-5px);
      }
      40% {
        transform: translateY(0px);
      }
      80% {
        transform: translateY(15px);
      }
      100% {
        transform: translateY(0);
      }
    }
  }

  .qr-col {
    flex-basis: 20%;
    div:not(:last-child) {
      margin-bottom: 20px;
    }
    .qr-box {
      background-color: #fff;
      margin: 0;
      .desc {
        background-color: #000;
        color: #fff;
        padding: 4px;
        margin: 0;
      }
    }
    .title {
      color: var(--primaryColor);
      font-size: 2rem;
    }
    .btn {
      background-color: var(--secondaryColor);
      border-radius: 4px;
      color: #fff;
      display: inline-block;
      padding: 5px;
      width: 80px;
      transition: $transition-all;
      &:not(:last-child) {
        margin-right: 5px;
      }
      &:hover {
        background-color: var(--primaryColor);
      }
      &.hide {
        display: none;
      }
    }
  }

  .girl-col {
    flex-basis: 40%;
    .girl-wrapper {
      position: relative;
      .img-girl {
        height: 600px;
      }
      .img-logo {
        position: absolute;
        top: 200px;
        left: 118px;
        width: 58px;
      }
    }
  }
}

/* *********** affindex *********** */
.affindex-top {
  background: url("../img/affbg.png") center no-repeat;
  color: #fff;
  .row {
    display: flex;
    align-items: center;
    justify-content: center;
    .col {
      text-align: center;
      flex-basis: 50%;
      .col-wrapper {
        margin: auto;
        width: fit-content;
      }
      .title {
        font-size: 3rem;
        font-weight: bold;
        line-height: 4rem;
        margin-bottom: 8px;
      }
      .apply {
        border-top: 1px solid #fff;
        font-size: 1.4rem;
        padding-top: 10px;
      }
    }
  }
}

.affindex-mid {
  background-color: #312a26;
  color: #fff;
  padding: 12px;
  text-align: center;
}

.affindex-content {
  .title {
    margin-top: 30px;
    font-size: 2rem;
    font-weight: bold;
    color: var(--primaryColor);
  }

  .btn-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .btn-color,
  .btn-color-hollow {
    box-shadow: 3px 2px #c5d1ef;
    border-radius: 5px;
    color: #fff;
    display: inline-block;
    font-size: 1rem;
    font-weight: bold;
    margin-right: 10px;
    padding: 10px 15px;
    text-align: center;
    width: 120px;
    transition: $transition-all;
    &.hide {
      display: none;
    }
    &:focus {
      outline: 0;
    }
  }

  .btn-color {
    background-color: var(--primaryColor);
    border: 2px solid transparent;
    &:hover {
      background-color: var(--primaryDarkColor);
      box-shadow: 3px 2px $shadow;
    }
  }

  .btn-color-hollow {
    background-color: #fff;
    border: 2px solid var(--primaryColor);
    color: var(--primaryColor);
    &:hover {
      background-color: var(--primaryDarkColor);
      box-shadow: 3px 2px $shadow;
    }
  }
  .num-row {
    background: url("../img/affball.png") top center no-repeat;
    display: flex;
    justify-content: center;
    line-height: 1.8rem;
    padding-top: 100px;
    .col {
      flex-basis: calc(100% / 3);
      text-align: center;
      .num-box {
        margin: auto;
        width: 50%;
        &.solve {
          margin-top: 260px;
        }
      }
    }
    .title {
      color: var(--primaryColor);
      font-size: 1.4rem;
      font-weight: bold;
      margin-bottom: 15px;
      margin-top: 10px;
    }
  }

  .benefit-row {
    box-sizing: border-box;
    padding: 70px 0;
    position: relative;
    .title-box {
      text-align: center;
      width: 360px;
      .title {
        color: var(--primaryColor);
        font-size: 3rem;
        font-weight: bold;
        margin-bottom: 28px;
      }
      .subtitle {
        color: var(--primaryColor);
        font-size: 1.4rem;
        line-height: 1.8rem;
        margin-bottom: 28px;
      }
    }
    ul {
      li {
        margin-bottom: 10px;
      }
      &.ul-benefit {
        list-style-image: url("../img/afftick_orange.svg");
      }
      &.ul-turnover {
        list-style: none;
        padding: 0;
        margin-top: 10px;
      }
    }
    .img-affgirl {
      position: absolute;
      bottom: 0;
      right: 0;
    }
    .img-logo {
      position: absolute;
      bottom: 500px;
      right: 267px;
      width: 85px;
    }
  }
}

/* *********** register *********** */
.register {
  background: url(../img/appbg.png) center no-repeat #dcdcdc;
  padding: 30px;
  min-height: $full-page-height;
  box-sizing: border-box;
}

.register-container {
  background-color: #fff;
  .reg-steps {
    width: 75%;
    left: 0;
    right: 0;
    margin: auto;
    background-color: $grey;
    &:after {
      content: "";
      display: table;
      clear: both;
    }

    .reg-steps-box {
      &.active {
        background: var(--primaryColor);
        color: #fff;
      }
      display: inline-block;
      vertical-align: middle;
      width: calc(100% / 3);
      text-align: center;
      border: 1px solid #c2c2c2;
      line-height: 32pt;
      float: left;
      box-sizing: border-box;
      position: relative;

      .steps-no {
        display: inline-block;
        vertical-align: middle;
        font-size: 18pt;
        color: #978e8e;
      }
      .steps-title {
        display: inline-block;
        vertical-align: middle;
        font-size: 10.5pt;
        color: #978e8e;
      }
      .steps-no.active,
      .steps-title.active {
        color: #fff;
      }
    }
  }

  .register-form {
    padding: 50px 0 30px 0;
    box-sizing: border-box;

    .row {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      &.button {
        justify-content: center;
        margin-top: 20px;
        button {
          background-color: var(--primaryColor);
          border: 0;
          border-radius: 4px;
          color: #fff;
          font-size: 1rem;
          padding: 8px;
          width: 120px;
          transition: $transition-all;
          &:hover {
            background-color: var(--secondaryColor);
          }
        }
      }

      .text {
        flex-basis: 20%;
        text-align: right;
        margin-right: 20px;
      }
      .inp {
        flex-basis: 30%;
        margin-right: 10px;
        input {
          border: 1px solid #c0c0c0;
          border-radius: 4px;
          box-sizing: border-box;
          font-size: 1rem;
          padding: 2px 10px;
          height: 32px;
          width: 100%;
          -webkit-appearance: none;
          background: transparent;
          outline: none;
        }
      }
      .tip {
        flex-basis: 50%;
        font-size: 0.9rem;
        display: flex;
        align-items: center;
      }
    }
  }
}

/* ***** promo ****** */
.promo {
  background: url(../img/appbg.png) center / cover fixed no-repeat #dcdcdc;
  padding: 30px;
  min-height: $full-page-height;
  box-sizing: border-box;
  .promo-wrapper {
    background-color: #fff;
    color: #333;
    padding: 10px;
    .icon {
      cursor: pointer;
      img {
        width: 100%;
      }
    }
    a {
      color: var(--primaryColor);
    }
    button {
      background-color: var(--primaryColor);
      border: 0;
      border-radius: 4px;
      color: #fff;
      outline: 0;
      padding: 8px 22px;
    }
    .sub-content-item-footer {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      height: 50px;
      margin-bottom: 20px;
    }
  }
}

.cooperation-row {
  margin-top: 30px;
  .cooperation-list {
    display: flex;

    > div {
      text-align: left;
    }

    .cooperation-title {
      flex-basis: 150px;
      font-weight: bold;

      &:after {
        content: ":";
      }
    }

    .cooperation-qrcode {
      width: 150px;
      background: white;
    }
  }
}
